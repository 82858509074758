









import {
  computed,
  defineComponent,
  onMounted,
  reactive,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import useEvent from "@/use/event";

export interface EventTypes {
  name: string;
  color: string | null;
  statuses: {
    name: string;
    color: string | null;
    number: number | string;
  }[];
}

export default defineComponent({
  setup(_, { root }) {
    const {
      getEventTypeColor,
      getEventTypeName,
      getEventStatusColor,
      getEventStatusName,
    } = useEvent({ root });

    const state = reactive({
      all: { all: 0, incoming: 0, in_progress: 0, complete: 0 },
      conference: { all: 0, incoming: 0, in_progress: 0, complete: 0 },
      online: { all: 0, incoming: 0, in_progress: 0, complete: 0 },
      conferences: { all: 0, incoming: 0, in_progress: 0, complete: 0 },
      course: { all: 0, incoming: 0, in_progress: 0, complete: 0 },
      reception: { all: 0, incoming: 0, in_progress: 0, complete: 0 },
      siemens: { all: 0, incoming: 0, in_progress: 0, complete: 0 },
    });

    const allTypes = [
      undefined,
      "conference",
      "online",
      "course",
      "reception",
      "siemens",
    ];
    const allStatuses = [undefined, "incoming", "in_progress", "complete"];

    const events = computed<EventTypes[]>(() => {
      const list: EventTypes[] = [];

      for (const type of allTypes) {
        const statuses: EventTypes["statuses"] = [];

        for (const status of allStatuses) {
          statuses.push({
            name: getEventStatusName(status || "") || "Wszystkie",
            color: getEventStatusColor(status || ""),
            number: (state as any)[type || "all"][status || "all"],
          });
        }

        list.push({
          name: getEventTypeName(type || "") || "Wszystkie",
          color: getEventTypeColor(type || ""),
          statuses,
        });
      }

      return list;
    });

    const fetch = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      axiosInstance
        .get("event/tiles")
        .then(({ data }) => {
          for (const type of allTypes) {
            for (const status of allStatuses) {
              (state as any)[type || "all"][status || "all"] =
                data.totals[`${type || "all"}_${status || "all"}`];
            }
          }
        })
        .catch(() => {
          for (const type of allTypes) {
            for (const status of allStatuses) {
              (state as any)[type || "all"][status || "all"] = 0;
            }
          }
        });
    };

    onMounted(fetch);

    return { state, events };
  },
});
